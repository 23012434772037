<template>
  <div class="resources-table">
    <div>
      <table-default
        id="table-resources"
        :rows="list"
        :fields="fields"
        :stickyHeader="false"
        :striped="true"
        :searchFilter="false"
        :filter="filter"
        @updatePageNumber="updatePageNumber"
        @updatepageSize="updatepageSize"
      >
        <template v-slot:head(code)="data">
          <div class="d-flex justify-content-between align-items-center">
            <span>
              {{ $t(data.label) }}
            </span>
            <div class="d-flex flex-column sortable-icons align-items-end">
              <ArrowDown
                @click="updateFieldAndSort(data.field.key, 'asc')"
                class="rotate"
                :style="
                  filter.sort === 'asc' && filter.fieldSort === data.field.key
                    ? 'fill: #998F8A'
                    : 'opacity: 0.5'
                "
              />
              <ArrowDown
                @click="updateFieldAndSort(data.field.key, 'desc')"
                :style="
                  filter.sort === 'desc' && filter.fieldSort === data.field.key
                    ? 'fill: #998F8A'
                    : 'opacity: 0.5'
                "
              />
            </div>
          </div>
        </template>
        <template v-slot:head(name)="data">
          <div class="d-flex justify-content-between align-items-center">
            <div>
              {{ $t(data.label) }}
            </div>
            <div class="d-flex flex-column sortable-icons align-items-end">
              <ArrowDown
                @click="updateFieldAndSort(data.field.key, 'asc')"
                class="rotate"
                :style="
                  filter.sort === 'asc' && filter.fieldSort === data.field.key
                    ? 'fill: #998F8A'
                    : 'opacity: 0.5'
                "
              />
              <ArrowDown
                @click="updateFieldAndSort(data.field.key, 'desc')"
                :style="
                  filter.sort === 'desc' && filter.fieldSort === data.field.key
                    ? 'fill: #998F8A'
                    : 'opacity: 0.5'
                "
              />
            </div>
          </div>
        </template>
        <template v-slot:head(resourceType)="data">
          <div class="d-flex justify-content-between align-items-center">
            <div>
              {{ $t(data.label) }}
            </div>
            <div class="d-flex flex-column sortable-icons align-items-end">
              <ArrowDown
                @click="updateFieldAndSort(data.field.key, 'asc')"
                class="rotate"
                :style="
                  filter.sort === 'asc' && filter.fieldSort === data.field.key
                    ? 'fill: #998F8A'
                    : 'opacity: 0.5'
                "
              />
              <ArrowDown
                @click="updateFieldAndSort(data.field.key, 'desc')"
                :style="
                  filter.sort === 'desc' && filter.fieldSort === data.field.key
                    ? 'fill: #998F8A'
                    : 'opacity: 0.5'
                "
              />
            </div>
          </div>
        </template>
        <template v-slot:cell(action)="data">
          <div class="action-block">
            <OptionIcon
              class="action-svg"
              @click="getItem(data.item)"
              :id="'action-' + data.index"
              tabindex="0"
            />
            <Popover
              :items="actionList"
              :target="'action-' + data.index"
              placement="bottomright"
              custom-position="action-button"
              triggers="focus"
            />
          </div>
        </template>
        <template v-slot:cell(resourceType)="data">
          <div>
            {{ getTextType(data.item.resourceTypeId) }}
          </div>
        </template>
      </table-default>
    </div>
  </div>
</template>

<script>
  import TableDefault from '@/@core/components/table-default/TableDefault.vue';
  import { mapMutations } from 'vuex';
  import OptionIcon from '@core/assets/icons/option-icon.svg';
  import DeleteIcon from '@core/assets/icons/delete-icon.svg';
  import EditIcon from '@core/assets/icons/edit-icon.svg';
  import ArrowDown from '@core/assets/icons/arrow-down.svg';
  import Popover from '@core/layouts/layout-horizontal/components/Menu/Popover.vue';
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
  import { BPagination, BFormSelect } from 'bootstrap-vue';

  export default {
    name: 'resources-table',
    display: 'Table',
    components: {
      BPagination,
      BFormSelect,
      Popover,
      ToastificationContent,
      TableDefault,
      OptionIcon,
      ArrowDown
    },
    props: {
      list: {
        type: Array,
        default: []
      },
      prefix: {
        type: String,
        default: ''
      },
      filter: {
        type: Object,
        default: null
      },
      typeList: {
        type: Array,
        default: []
      }
    },
    data() {
      return {
        currentItem: null,
        fields: [
          {
            label: 'IntegrationPage.Actions',
            key: 'action',
            sortable: false,
            thClass: 'col-action-type th',
            tdClass: 'td'
          },
          {
            label: 'ResourcesList.Code',
            key: 'code',
            sortable: false,
            thClass: 'th col-code',
            tdClass: 'td'
          },
          {
            label: 'ResourcesList.Name',
            key: 'name',
            sortable: false,
            thClass: 'th col-name',
            tdClass: 'td'
          },
          {
            label: 'ResourcesList.Type',
            key: 'resourceType',
            sortable: false,
            thClass: 'col-type th',
            tdClass: 'td'
          }
        ],
        actionList: [
          {
            title: 'ResourcePage.Edit',
            icon: EditIcon,
            click: () => {
              this.$root.$emit('bv::toggle::collapse', 'sidebar-right');
              this.$emit('edit', this.currentItem.id);
            }
          },
          {
            title: 'ResourcePage.Delete',
            icon: DeleteIcon,
            click: () => this.deleteResource()
          }
        ]
      };
    },
    methods: {
      ...mapMutations('app', ['UPDATE_FLAG_SHOW_OVERLAY']),
      getTextType(type) {
        if (type.value) {
          return this.typeList.filter((e) => e.value === type.value)[0]?.text;
        }
        return type ? this.typeList.filter((e) => e.value === type)[0]?.text : '-';
      },
      getItem(item) {
        this.currentItem = item;
      },
      showToast(title, icon, text, variant) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: title,
            icon: icon,
            text: text,
            variant
          }
        });
      },
      updatePageNumber(e) {
        this.$emit('updatePageNumber', e);
      },
      updatepageSize(e) {
        this.$emit('updatepageSize', e);
      },
      updateFieldAndSort(field_key, text) {
        this.$emit('updateFieldAndSort', field_key, text);
      },
      deleteResource() {
        if (this.currentItem.hasProductionProgress) {
          this.$swal({
            title: this.$t('IntegrationPage.attention'),
            html: `<div>${this.$t('ResourcePage.DeleteMessage1')} <b>${
              this.currentItem.name
            }</b>. ${this.$t('ResourcePage.DeleteMessage2')}</div>`,
            showCancelButton: true,
            showCloseButton: true,
            cancelButtonText: this.$t('IntegrationPage.cancel'),
            confirmButtonText: this.$t('IntegrationPage.delete'),
            customClass: {
              container: 'swal-conection',
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1'
            },
            buttonsStyling: false
          }).then((result) => {
            if (result.value) {
              this.onDeleteRoute();
            }
          });
        } else {
          this.onDeleteRoute();
        }
      },
      onDeleteRoute() {
        this.UPDATE_FLAG_SHOW_OVERLAY(true);
        this.$http2
          .delete(`/api/myconfig/resource/${this.currentItem.id}`)
          .then(() => {
            this.$emit('deletedResource', this.list);
            this.showToast(
              this.$t('IntegrationPage.success'),
              'SmileIcon',
              this.$t('ResourcePage.DeleteSuccess'),
              'success'
            );
          })
          .catch((error) => {
            this.UPDATE_FLAG_SHOW_OVERLAY(false);
            this.showToast(this.$t('Error'), 'FrownIcon', error.response.data.message, 'danger');
          });
      }
    }
  };
</script>
<style lang="scss">
  .swal-conection {
    .swal2-modal {
      padding: 0 !important;
      border-radius: 6px;
      width: 710px;

      .swal2-header {
        background-color: #d32f2f;
        padding: 20px 16px;
        border-radius: 6px 6px 0px 0px;
        flex-direction: row;
        justify-content: left;

        .swal2-title {
          color: #ffff;
          font-weight: 600;
          font-size: 18px;
          line-height: 26px;
          margin: 0;
        }

        .swal2-close {
          border-radius: 6px;
          width: 34px;
          background-color: #ffff;
          height: 34px;
          top: -5px;
          position: absolute;
          right: -5px;
          padding-bottom: 5px;
        }
      }

      .swal2-content {
        padding: 20px 16px;

        .swal2-html-container {
          font-weight: 400;
          text-align: left;
          font-size: 14px;
          line-height: 20px;
          color: #4c4541;
          display: flex !important;
        }
      }

      .swal2-actions {
        padding: 0 16px 20px 16px;
        display: flex;
        justify-content: right;

        .swal2-confirm {
          margin-left: 10px !important;
          order: 2;
          font-weight: 600;
          font-size: 17px;
          line-height: 20px;
          color: #ffff;
          border-color: #974900 !important;
          background-color: #974900 !important;
          border-radius: 5px;

          &:hover {
            box-shadow: none !important;
          }
        }

        .swal2-cancel {
          order: 1;
          font-weight: 600;
          font-size: 17px;
          line-height: 20px;
          color: #974900 !important;
          border-radius: 5px;
          border-color: transparent !important;

          &:hover {
            background-color: #ffede1 !important;
          }
        }
      }
    }
  }

  .resources-table {
    .col-action-type {
      min-width: 57px !important;
    }

    table {
      border-radius: 6px;
      border: 1px solid #e0e0e0;
      margin: 0 1px !important;
    }

    .th {
      padding: 11px 5px 11px 7px;
      color: #4c4541;
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      vertical-align: middle;

      .sortable-icons {
        min-width: 20%;
        .rotate {
          transform: rotate(180deg);
        }
      }

      svg {
        cursor: pointer;
        width: 12px;
        height: 12px;
        fill: #998f8a;
      }
    }

    .td {
      padding: 14.5px 10px;
    }

    tr {
      &:last-child {
        border-bottom: 1px solid #e0e0e0;
      }
    }

    .col-code {
      width: 9% !important;
      min-width: 87px !important;
    }

    .col-name {
      width: 70% !important;
      min-width: 767px !important;
    }

    .col-type {
      width: 15% !important;
      min-width: 134px !important;
    }

    .action-block {
      display: flex;
      position: relative;
      .action-svg {
        fill: #974900;
        width: 28px;
        height: 28px;
        cursor: pointer;

        &:hover {
          background-color: #ffede2;
          border-radius: 5px;
        }

        &:active {
          background-color: #ffdbc4;
          border-radius: 5px;
        }

        &:focus-within,
        &:focus {
          background-color: #ffdbc4;
          border-radius: 5px;
          border: none;
          outline: none;
        }
      }
      .handle {
        display: none;
        position: absolute;
        left: 30px;
      }
    }
  }
</style>
